<template lang="pug">
  main#main.manager-machines-models
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-tags')
      section.l-section
        .l-section-h
          router-view
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'manager-machines-models',
  components: {
    WcBreadcrumb,
  },
}
</script>
